import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, Observable, filter, lastValueFrom, shareReplay, switchMap, take, tap } from 'rxjs';

import { ApiService } from './api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = '';

  private refresh = new BehaviorSubject<void>(undefined);
  whoami$: Observable<any>;
  leaders$: Observable<any>;

  constructor(private api: ApiService, private router: Router) {
    this.leaders$ = this.api.getLeaders();
    this.whoami$ = this.refresh.pipe(
      take(1),
      switchMap(() => this.api.whoami()),
      shareReplay(1)
    );
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      switch (event.urlAfterRedirects) {
        case '/':
          this.title = 'Games';
          break;
        case '/ferdle':
          this.title = 'Ferdle';
          break;
        case '/%D1%84%D0%B5%D1%80%D0%B4%D0%BB':
          this.title = 'Фердл';
          break;
        case '/%D1%84%D0%B5%D1%80%D0%B4%D0%BB-5':
          this.title = 'Фердл-5';
          break;
      }
    });
    document.addEventListener('touchmove', (event: any) => {
      if (event.scale !== 1) { event.preventDefault(); }
    }, { passive: false });
  }

  async ngOnInit(): Promise<void> {
    await lastValueFrom(this.whoami$)
      .then(profile => { if (!profile.allowed) this.router.navigate(['/nouser']); })
      .catch(() => window.location.href = '/auth/google');
  }

  async logout() {
    await lastValueFrom(this.api.logout());
    this.refresh.next();
    this.router.navigate(['/']);
  }
}
