import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  logout(): Observable<any> {
    return this.POST('/auth/logout');
  }

  whoami(): Observable<any> {
    return this.GET('/api/whoami');
  }

  getLeaders(): Observable<any> {
    return this.GET('/api/leaders');
  }

  getDictionary(langauge: string, length: number): Observable<Set<string>> {
    return this.GET(`/api/dictionary?language=${langauge}&length=${length}`);
  }

  loadState(language: string, length: number): Observable<any> {
    return this.GET(`/api/load?language=${language}&length=${length}`);
  }

  saveState(language: string, length: number, state: any): Observable<any> {
    return this.POST(`/api/save?language=${language}&length=${length}`, state);
  }

  private GET(url: string, params?: any): Observable<any> {
    return this.http.get(url, { ...params, withCredentials: true });
  }

  private POST(url: string, body?: any): Observable<any> {
    return this.http.post(url, body, { withCredentials: true });
  }
}
